import React, { useState, useRef, useEffect } from "react";
import { Col, Row, OverlayTrigger, Popover } from "react-bootstrap";
import { CgFigma, CgTrello } from "react-icons/cg";
import { DiIntellij, DiVisualstudio } from "react-icons/di";
import {
  SiVisualstudiocode,
  SiPostman,
  SiSlack,
  SiVercel,
  SiMacos,
  SiWindows,
  SiWindows11,
  SiIntellijidea,
  SiMicrosoftword,
} from "react-icons/si";
import { TbBrandBlender } from "react-icons/tb";

const popover = (name, description, link) => (
  <Popover id="popover-basic">
    <Popover.Header as="h3" className="purple fw-bold">{name}</Popover.Header>
    <Popover.Body>
      {description}
      <br />
      <a href={link} target="_blank" rel="noopener noreferrer">Learn more</a>
    </Popover.Body>
  </Popover>
);

function Toolstack() {
  const [activePopover, setActivePopover] = useState(null);
  const toolstackRef = useRef(null);

  const tools = [
    { Icon: SiWindows11, name: "Windows 11", description: "The latest version of Microsoft's operating system.", link: "https://www.microsoft.com/windows/windows-11" },
    { Icon: SiVisualstudiocode, name: "Visual Studio Code", description: "A lightweight but powerful source code editor.", link: "https://code.visualstudio.com/" },
    { Icon: DiVisualstudio, name: "Visual Studio", description: "An integrated development environment from Microsoft.", link: "https://visualstudio.microsoft.com/" },
    { Icon: SiPostman, name: "Postman", description: "A popular API client that makes it easy to create, share, test and document APIs.", link: "https://www.postman.com/" },
    { Icon: CgTrello, name: "Trello", description: "A web-based, Kanban-style, list-making application.", link: "https://trello.com/" },
    { Icon: SiVercel, name: "Vercel", description: "A cloud platform for static sites and Serverless Functions.", link: "https://vercel.com/" },
    { Icon: SiIntellijidea, name: "IntelliJ IDEA", description: "A capable and ergonomic Java IDE for enterprise Java development.", link: "https://www.jetbrains.com/idea/" },
    { Icon: SiMicrosoftword, name: "Microsoft Word", description: "A word processor developed by Microsoft.", link: "https://www.microsoft.com/microsoft-365/word" },
    { Icon: CgFigma, name: "Figma", description: "A vector graphics editor and prototyping tool.", link: "https://www.figma.com/" },
    { Icon: TbBrandBlender, name: "Blender", description: "A free and open-source 3D creation suite.", link: "https://www.blender.org/" },
  ];

  useEffect(() => {
    function handleClickOutside(event) {
      if (toolstackRef.current && !toolstackRef.current.contains(event.target)) {
        setActivePopover(null);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleIconClick = (index) => {
    setActivePopover(activePopover === index ? null : index);
  };

  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }} ref={toolstackRef}>
      {tools.map((tool, index) => (
        <OverlayTrigger
          key={index}
          trigger="click"
          placement="auto"
          show={activePopover === index}
          overlay={popover(tool.name, tool.description, tool.link)}
        >
          <Col xs={4} md={2} className="tech-icons" onClick={() => handleIconClick(index)}>
            <tool.Icon />
          </Col>
        </OverlayTrigger>
      ))}
    </Row>
  );
}

export default Toolstack;