import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import instagram from '../../Assets/qrs/insta.png';

const spanStyle = {
  padding: '20px',
  background: '#efefef',
  color: '#000000'
};

const divStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'cover',
  height: '400px'
};

const slideImages = [
  {
    url: 'https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80',
    title: 'Instagram',
    link: 'https://www.instagram.com'
  },
  {
    url: 'https://images.unsplash.com/photo-1506710507565-203b9f24669b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1536&q=80',
    title: 'Twitter',
    link: 'https://www.twitter.com'
  },
  {
    url: 'https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80',
    title: 'Facebook',
    link: 'https://www.facebook.com'
  },
];

const QrPopUp = (props) => {
  const [currentTitle, setCurrentTitle] = useState(slideImages[0].title);

  const handleBeforeChange = (oldIndex, newIndex) => {
      console.log(slideImages[newIndex].title);
    setCurrentTitle(slideImages[newIndex].title);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {currentTitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="slide-container">
          <Slide beforeChange={handleBeforeChange}>
            {slideImages.map((slideImage, index) => (
              <div key={index}>
                <div style={{ ...divStyle, backgroundImage: `url(${slideImage.url})` }}>
                  <span style={spanStyle}>{slideImage.title}</span>
                </div>
              </div>
            ))}
          </Slide>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className='button btn-outline-danger' onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default QrPopUp;
