import React, { useState } from 'react';
import './ContactComponent.css';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';
import { FaEnvelope } from 'react-icons/fa';
import { Container } from 'react-bootstrap';
import Particle from '../Particle';



function ContactComponent(){
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');


  const handleSubmit = (e) => {
    console.log(process.env)
    debugger;
    e.preventDefault();
    setIsLoading(true);

    if (
      firstName.trim() === '' ||
      lastName.trim() === '' ||
      email.trim() === '' ||
      message.trim() === ''
    ) {

      alertFail();
      setIsLoading(false);
    } else if (!validateEmail(email)) {
      alertInvalidEmail();
      setIsLoading(false);
    } else {

      emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, {
          first_name: firstName,
          last_name: lastName,
          email: email,
          message: message,
        }, process.env.REACT_APP_EMAILJS_USER_ID)
        .then((result) => {
          console.log(result.text);
          alertSuccess();
          setTimeout(() => {
            setFirstName('');
            setLastName('');
            setEmail('');
            setMessage('');
            setIsLoading(false);
          }, 1500);
        })
        .catch((error) => {
          console.log(error.text);
          setIsLoading(false);
        });
    }
  };

  const validateEmail = (email) => {
    // Regular expression for email validation
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  const alertSuccess = () => {
    Swal.fire({
      position: 'top',
      icon: 'success',
      title: 'Email successfully sent!',
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const alertFail = () => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'All input fields are required!',
    });
  };

  const alertInvalidEmail = () => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Please enter a valid email address!',
    });
  };

  return (
   <Container fluid className="about-section">
      {/* <Particle /> */}

      <p className='contact-small-title'>Get In Touch 👆🏻</p>
      <div className='contact-text'>
        Be free to write me a message. I will answer you as soon as possible.
      </div>
      <Container className='contact-container'>
        <div className='contact-sub-container'>
          <div className='name-container'>
            <div className='first-name-container'>
              <label className='label-name'>First name: </label>
              <input
                className='input-name'
                type='text'
                name='first_name'
                placeholder='Insert your name'
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className='last-name-container'>
              <label className='label-last-name'>Last name: </label>
              <input
                className='input-last-name'
                type='text'
                name='last_name'
                placeholder='Insert your last name'
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>
          <div className='email-container'>
            <label className='label-email'>Email: </label>
            <input
              className='input-email'
              type='email'
              name='email'
              placeholder='Insert your email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              pattern='^[^\s@]+@[^\s@]+\.[^\s@]+$'
              required
            />
          </div>
          <div className='message-container'>
            <label className='label-message'>Message: </label>
            <textarea
              className='textarea-message'
              rows='8'
              name='message'
              placeholder='Insert your message'
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </div>
          <div className='button-email-container'>
            <div className='btn-send-email' onClick={handleSubmit}>
              {isLoading ? 'Sending...' : 'Send email'}
              <FaEnvelope className='icon-email' />
            </div>
          </div>
        </div>
      </Container>
    </Container>
  );
};

export default ContactComponent;
