import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
  AiOutlineSwitcher,
  AiOutlineQrcode,
} from "react-icons/ai";
import { FaLinkedinIn, FaQrcode } from "react-icons/fa";
import QrPopUp from "./QrPopUp";



function Home2() {
  const [qrMode, setQrMode] = useState(false);
  // function to switch to qr code of social links

  const switchToQR = () => {
    setQrMode(!qrMode);
  }

  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
              I fell in love with programming and I have at least learnt
              something, I think… 🤷‍♂️
              <br />
              <br />I am fluent in classics like
              <i>
                <b className="purple"> Python, Javascript and C#. </b>
              </i>
              <br />
              <br />
              My field of Interest's are building new &nbsp;
              <i>
                <b className="purple">Web Technologies and Products </b> 
              </i>
                and
                also in areas related to{" "}
                <b className="purple">
              <i>
                Machine learning & Artificial intelligence.
              </i>
                </b>
              <br />
              <br />
              
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            {/* <p>
              Feel free to <span className="purple">connect </span>with me
            </p> */}
          </Col>
          </Row>
          <Row>
          <Col md={3} className="home-about-social-list">
               <li className="social-icons">
                <a
                  href="https://rb.gy/6jyvs1"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  title="Github => 'kafle-kalyan1'"
                >
                  <AiFillGithub /> 

                </a>
                <span className="icon-name">
                  Github
                </span>
              </li>
            </Col>

            <Col md={3} className="home-about-social-list">
               <li className="social-icons">
               <a
                  href="https://rb.gy/13o8mu"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  title="Twitter => '@kafle_kalyan1'"
                >
                  <AiOutlineTwitter />
                </a>
                <span className="icon-name">
                  Twitter
                </span>
              </li>
            </Col>

            <Col md={3} className="home-about-social-list">
               <li className="social-icons">
               <a
                  href="https://rb.gy/70sq9q"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  title="Linkedin => 'kafle-kalyan1'"
                >
                  <FaLinkedinIn />
                </a>
                <span className="icon-name">
                  Linked In
                </span>
              </li>
            </Col>

            <Col md={3} className="home-about-social-list">
               <li className="social-icons">
               <a
                  href="https://rb.gy/m00j42"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                  title="Instagram => '_kalyan.0'"
                >
                  <AiFillInstagram />
                </a>
                <span className="icon-name">
                  Instagram
                </span>
              </li>
            </Col>
            </Row>


          <Col md={12} className="home-about-social">
          {/* <button className="btn btn-outline-success" 
                title="View QR code of social links"
                onClick={switchToQR}
                >
                  <AiOutlineQrcode /> View QR code
                </button> */}
          </Col>
        {
          qrMode ? (
            <QrPopUp 
            show={qrMode}
            onHide={() => setQrMode(false)}
             />
          ) : (
            <></>
          )
        }
      </Container>
    </Container>
  );
}
export default Home2;
