import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Kalyan Babu Kafle </span>
            from  Itahari, Nepal.
            <br />
            I am currently employed as a software developer at <span className="purple"> Aegis Software.</span>
            <br />
            I have completed  <span className="purple"> BSc (Hons) Computing </span> at <span className="purple">Itahari International College. </span>
            <br />
            My hobby is this and that, and that.


            <br />
            <br />
            On Weekend, some other activities that I love to do!
          </p>

            <ul>
            <li className="about-activity">
              <ImPointRight /> Playing Games
            </li>
            <li className="about-activity">
              <ImPointRight /> Writing Blogs
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling
            </li>
          </ul>

           
<hr />
          <p style={{ color: "rgb(155 126 172)" }}>
            "The Web does not just connect machines, it connects people."{" "}
          </p>
          <footer className="blockquote-footer">Tim Berners-Lee </footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
