import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
                options={{
                  strings: [
                    "Lifelong Learner",
                    "Web Developer",
                    "Passionate Gamer",
                    "Problem Solver",
                    "Curious Explorer",
                    "Dream Chaser",
                  ],
                  
                  autoStart: true,
                  loop: true,
                  TimeRanges: 2000,
                  deleteSpeed: 50,
                  deleteDelay: 1000,
                  deleteAll: true,
                  cursor: "_",
                  smartBackspace: true,
                }}
              />
  );
}

export default Type;
