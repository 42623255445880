import React from "react";
import GitHubCalendar from "react-github-calendar";
import { Row, Col, Container } from "react-bootstrap";

function Github() {
  return (
    <>
<Container>
    <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
      <h1 className="project-heading" style={{ paddingBottom: "20px" }}>
        Days I <strong className="purple">Code</strong>
      </h1>
      <GitHubCalendar
        username="kafle-kalyan1"
        blockSize={15}
        blockMargin={5}
        color="#53c776"
        fontSize={16}
      />
    </Row>
    <Row style={{ justifyContent: "center", padding: "10px" }}>
      <Col>
        <p>
          Total Coding Hours since 2023 June 30
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <a href="https://wakatime.com/@d33d118b-65bc-4810-898a-d3f855131306"><img src="https://wakatime.com/badge/user/d33d118b-65bc-4810-898a-d3f855131306.svg" alt="Total time coded since Jun 30 2023" /></a>
        </p>
      </Col>
      </Row>
      </Container>
    </>
  );
}

export default Github;
